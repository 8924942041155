import * as TYPES from "./types";
import * as API from "./api";
import {requestStatus} from "@/services/services";

export default {
    [TYPES.LOAD]({commit}: any) {
        let status = requestStatus.loading;
        let messages = '';
        let field = "load";
        commit(TYPES.SET_STATUS, {status, field});
        
        API.loadShops()
            .then(({response, status: loaderStatus, messages: responseMessages}: any) => {
                console.log(responseMessages, response);
                status = loaderStatus;
                messages = responseMessages;
                if (status === requestStatus.success) {
                    commit(TYPES.RECEIVE, response.data);
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field,
                    messages
                });
            });
    },
    [TYPES.LOAD_SETTINGS]({commit}: any) {
        let status = requestStatus.loading;
        let field = "load_settings";
        commit(TYPES.SET_STATUS, {status, field});
        
        API.loadSettings()
            .then(({response, status: loaderStatus}: any) => {
                status = loaderStatus;
                if (status === requestStatus.success) {
                    commit(TYPES.RECEIVE_SETTINGS, response.data);
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field
                });
            });
    },
    [TYPES.EDIT]({commit}: any, data: any) {
        let status = requestStatus.loading;
        let field = "edit_settings";
        commit(TYPES.SET_STATUS, {status, field});

        API.editSettings(data)
            .then(({response, status: loaderStatus}: any) => {
                status = loaderStatus;
                if (status === requestStatus.success) {
                    commit(TYPES.RECEIVE_SETTINGS, response.data);
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field
                });
            });
    },
    [TYPES.CHANGE_PLAN]({commit}: any, data: any) {
        let status = requestStatus.loading;
        let field = "change_plan";
        commit(TYPES.SET_STATUS, {status, field});

        API.changePlan(data)
            .then(({response, status: loaderStatus}: any) => {
                status = loaderStatus;
                if (status === requestStatus.success) {
                    commit(TYPES.SET_TARIFF_REDIRECT, response.data.redirect_url);
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field
                });
            });
    },
    [TYPES.ACCEPT_PLAN]({commit}: any, data: any) {
        let status = requestStatus.loading;
        let field = "accept_plan";
        commit(TYPES.SET_STATUS, {status, field});

        API.acceptPlan(data)
            .then(({status: loaderStatus}: any) => {
                status = loaderStatus;
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field
                });
            });
    },

}